<template>
  <div>
    <Accordion class="rounded-lg border shadow border-grey-light-4 flex">
      <div
        class="w-8 rounded-l-lg"
        :style="{ 'background-color': `${color}` }"
      />
      <AccordionItem class="max-w-question">
        <template slot="accordion-trigger">
          <QuestionItem :index="index" :id="id" />
        </template>
        <template slot="accordion-content">
          <ReadOnlyQuestion v-if="isReadOnly" :id="id" />
          <QuestionContent v-else :index="index" :id="id" />
        </template>
      </AccordionItem>
    </Accordion>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

import QuestionItem from "./QuestionItem.vue";
import QuestionContent from "./QuestionContent.vue";
import Accordion from "../../../../components/Accordion/Accordion.vue";
import AccordionItem from "../../../../components/Accordion/AccordionItem.vue";
import ReadOnlyQuestion from "./ReadOnlyQuestion.vue";

export default {
  name: "Questions",
  props: {
    index: { default: null, type: Number },
    id: { default: null, type: Number },
  },
  components: {
    Accordion,
    AccordionItem,
    QuestionItem,
    QuestionContent,
    ReadOnlyQuestion,
  },
  computed: {
    ...mapState({
      questions: (state) => state.products.questions,
    }),
    ...mapGetters({
      isReadOnly: "products/isReadOnly",
    }),
    currentQuestion() {
      return this.questions.find((item) => item.subCompetencyId === this.id);
    },
    color() {
      return this.currentQuestion?.competencyColor;
    },
  },
};
</script>
