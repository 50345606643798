<template>
  <div
    class="h-auto w-full flex items-center"
    :class="isActive ? 'h-auto' : 'min-h-96'"
  >
    <div class="flex justify-center ml-1 text-blue-dark-1 font-bold">
      Q{{ index + 1 }}
    </div>
    <div class="flex justify-center ml-2">
      <img class="min-w-2" src="../../../../assets/img/icons/bullet-list.svg" />
    </div>
    <div class="flex flex-col justify-center ml-2 min-w-4/5">
      <div class="w-full mt-1.5 mb-1.5">
        <p
          class="font-bold break-words"
          :class="hasQuestionTitle ? 'text-grey-dark-1' : 'text-grey-light'"
        >
          {{ questionTitle }}
        </p>
        <div v-show="!isActive" class="flex transition-all">
          <p class="text-sm-2 text-grey-light font-bold mr-0.4">
            {{ competencyName }}
          </p>
          <p class="text-sm-2 text-grey-light font-normal">
            / {{ subCompetencyName }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex justify-center ml-auto mr-2">
      <img
        src="../../../../assets/img/delta-table/caret.svg"
        class="transition-all min-w-24 h-24"
        :class="isActive ? 'transform rotate-180' : null"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "QuestionItem",
  inject: ["Accordion"],
  props: {
    index: { default: null, type: Number },
    id: { default: null, type: Number },
  },
  computed: {
    ...mapState({
      questions: (state) => state.products.questions,
    }),
    isActive() {
      return this.Accordion.active === 0;
    },
    currentQuestion() {
      return this.questions.find((item) => item.subCompetencyId === this.id);
    },
    questionTitle() {
      const text = this.currentQuestion.text;
      return text ? text : "Type something in the question text field below.";
    },
    competencyName() {
      return this.currentQuestion.competencyName;
    },
    subCompetencyName() {
      return this.currentQuestion.subCompetencyName;
    },
    hasQuestionTitle() {
      const text = this.currentQuestion.text;
      return !!text;
    },
  },
};
</script>
