<template>
  <div>
    <div class="flex">
      <div class="flex">
        <p class="font-barlow font-medium text-xl text-grey-medium-2">
          Survey Details /
        </p>
        <p class="font-barlow font-bold text-xl text-grey-dark-2 ml-1">
          Questions
        </p>
      </div>
      <div class="flex flex-grow justify-end gap-2">
        <Button text="Back" type="primary-white" size="medium" :iconLeft="arrowLeft" :rotateArrow="true"
          @click.native="goBack" />
        <Button v-if="!isReadOnly" text="Next" type="primary" size="medium" @click.native="goNext"
          :iconRight="arrowRight" />
      </div>
    </div>
    <div class="bg-white shadow rounded px-2 pt-3 mt-3 pb-4.5 mb-4">
      <div class="mb-3">
        <p class="font-barlow font-bold text-mdl text-grey-light mb-1">
          Questions types and Sub-competency Mapping.
        </p>
        <p class="text-mdh text-grey-dark-1 max-w-3/5 mb-5">
          Define questions and answer choices for this survey. You also need to
          assign appropriate scoring values and map sub-competencies to each
          question type.
        </p>
        <div class="text-grey-dark-1 text-md mb-4 pr-4">
          <p>
            The score options you choose from for each Competency Level are:
          </p>
          <div class="mt-1 flex flex-col ow">
            <p v-for="{ name, score } in scores" :key="score" class="mr-3">
              <span class="font-bold">{{ score }}.0</span>
              = {{ name }}
            </p>
          </div>
        </div>
        <div class="flex justify-between">
          <InputField label="Filter by" placeholder="Question or competency name" leftIcon :onChange="setKeyword"
            :propValue="keyword" class="w-inputField" />
          <Select :options="options" :onSelect="sortQuestions" label="Sort by" class="w-275" />
        </div>
      </div>
      <div>
        <div>
          <Questions v-for="(question, index) in filteredQuestions" :key="question.subCompetencyId" :index="index"
            :id="question.subCompetencyId" class="mb-1" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import debounce from "lodash/debounce";
import arrowLeft from "@/assets/img/icons/arrow-right.svg";
import arrowRight from "@/assets/img/icons/arrow-right-white.svg";

import InputField from "../../../components/InputField/InputField.vue";
import Select from "../../../components/Select/NewSelect.vue";
import Questions from "../components/Questions";

export default {
  name: "ProductQuestions",
  components: { InputField, Select, Questions },
  data: () => ({
    arrowLeft,
    arrowRight,
    keyword: "",
    options: [
      // { label: "Question Number - Ascending", id: "1" },
      // { label: "Question Number - Descending", id: "2" },
      { label: "Competency - Ascending", id: "3" },
      { label: "Competency - Descending", id: "4" },
    ],
    filteredQuestions: [],
  }),
  watch: {
    surveyCompetencies() {
      this.setDefaultQuestions();
    },
    questions(value) {
      this.filteredQuestions = value;
    },
    keyword(value) {
      this.filteredQuestions = this.questions.filter((item) => {
        const competency = item?.competencyName?.toLowerCase();
        const question = item?.text.toLowerCase();
        return (
          competency?.includes(value.toLowerCase()) ||
          question?.includes(value.toLowerCase())
        );
      });
    },
  },
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
      scores: (state) => state.products.scores,
      surveyCompetencies: (state) => state.products.surveyCompetencies,
      questions: (state) => [...state.products.questions].sort((a, b) => a.competencyName.localeCompare(b.competencyName)),
    }),
    ...mapGetters({
      isReadOnly: "products/isReadOnly",
    }),
  },
  methods: {
    ...mapActions({
      getSurveyCompetencies: "products/getSurveyCompetencies",
      setDefaultQuestions: "products/setDefaultQuestions",
    }),
    goBack() {
      if (this.isReadOnly) {
        this.$router.push(
          `/products/${this.productDetails.productId}/dashboard`
        );
      } else {
        this.$router.push("scores");
      }
    },
    goNext() {
      this.$router.push("dashboard");
    },
    sortQuestions({id}) {
      switch (id) {
        case "1":
          return this.filteredQuestions.sort((a, b) => a.id - b.id);
        case "2":
          return this.filteredQuestions.sort((a, b) => b.id - a.id);
        case "3":
          return this.filteredQuestions.sort((a, b) => a.competencyName.localeCompare(b.competencyName));
        case "4":
          return this.filteredQuestions.sort((a, b) => b.competencyName.localeCompare(a.competencyName));
        default:
          break;
      }

    },
    setKeyword: debounce(function (value) {
      this.keyword = value;
    }, 500),
  },
  async mounted() {
    await this.getSurveyCompetencies(this.productDetails.id);
    this.setDefaultQuestions();
  },
};
</script>
<style scoped lang="scss">
.ow {
  overflow-wrap: break-word;
  overflow: hidden;
}
</style>
