<template>
  <div class="w-600 ml-8.5 pb-3 flex flex-col">
    <div class="mb-4">
      <p class="text-grey-dark-1 font-normal mb-2">Question</p>
      <div class="ml-3">
        <div class="mb-2">
          <p class="text-grey-light text-sm-2 mb-1">Question Type</p>
          <p class="text-grey-dark-1 text-mdh">Multiple Choice</p>
        </div>
        <div class="mb-2">
          <p class="text-grey-light text-sm-2 mb-1">Question Text</p>
          <p class="text-grey-dark-1 text-mdh">{{ currentQuestion.text }}</p>
        </div>
        <div class="flex gap-5.5">
          <div>
            <p class="text-grey-light text-sm-2 mb-1">Associated Competency</p>
            <p class="text-grey-dark-1 text-mdh">
              {{ currentQuestion.competencyName }}
            </p>
          </div>
          <div>
            <p class="text-grey-light text-sm-2 mb-1">
              Associated Sub-competency
            </p>
            <p class="text-grey-dark-1 text-mdh">
              {{ currentQuestion.subCompetencyName }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <p class="text-grey-dark-1 font-normal mb-2">Answer Options</p>
      <div class="ml-3">
        <div
          v-for="answer in currentQuestion.responses"
          :key="answer.id"
          class="flex gap-4 mb-3"
        >
          <div>
            <p class="text-grey-light text-sm-2 mb-1">Answer Option</p>
            <p class="text-grey-dark-1 text-mdh">
              {{ answer.text }}
            </p>
          </div>
          <div class="ml-auto">
            <p class="text-grey-light text-sm-2 mb-1">
              Score
            </p>
            <p class="text-grey-dark-1 text-mdh">
              {{ answer.value }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "ReadOnlyQuestion",
  props: {
    id: { default: null, type: Number },
  },
  computed: {
    ...mapState({
      questions: (state) => state.products.questions,
    }),
    currentQuestion() {
      return this.questions.find((item) => item.subCompetencyId === this.id);
    },
  },
};
</script>
