<template>
  <div class="w-600 ml-8.5 pb-3 flex flex-col">
    <p class="text-grey-dark-1 font-normal mb-2">Question</p>
    <div class="ml-3 mb-6">
      <Select
        :options="questionTypes"
        label="Question Type"
        class="mb-3"
        hasLeftIcon
        :leftIcon="bulletList"
        preselectFirst
      />
      <TextArea
        label="Question Text"
        class="mb-3"
        :onChange="(value) => (text = value)"
        :defaultValue="text"
        max-length="500"
      />
      <InputField
        label="Associated Competency"
        :defaultValue="competencyName"
        disabled
        class="mb-3"
      />
      <InputField
        label="Associated Sub-competency"
        :defaultValue="subCompetencyName"
        disabled
      />
    </div>
    <p class="text-grey-dark-1 font-normal mb-2">Answer Options</p>
    <div class="ml-3 mb-3">
      <AnswerScore
        v-for="(item, index) in scores"
        :key="item.score"
        :index="index"
        :id="id"
      />
    </div>
    <Button
      text="Save"
      type="primary"
      size="medium"
      class="ml-auto px-5"
      :disabled="disableSave"
      @click.native="save"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import bulletList from "@/assets/img/icons/bullet-list-gray.svg";

import AnswerScore from "./AnswerScore.vue";
import Button from "../../../../components/Button/Button.vue";
import TextArea from "../../../../components/TextArea";
import InputField from "../../../../components/InputField/InputField.vue";
import Select from "../../../../components/Select/NewSelect.vue";

export default {
  name: "QuestionContent",
  props: {
    index: { default: null, type: Number },
    id: { default: null, type: Number },
  },
  components: { Button, TextArea, InputField, Select, AnswerScore },
  data: () => ({
    questionTypes: [{ label: "Single Choice", id: "1" }],
    bulletList,
  }),
  computed: {
    ...mapState({
      scores: (state) => state.products.scores,
      questions: (state) => state.products.questions,
      productDetails: (state) => state.products.productDetails,
    }),
    currentQuestion() {
      return this.questions.find((item) => item.subCompetencyId === this.id);
    },
    text: {
      get() {
        return this.currentQuestion.text;
      },
      set(value) {
        this.setQuestionText({
          id: this.currentQuestion.subCompetencyId,
          text: value,
        });
      },
    },
    disableSave() {
      const hasAllResponses = this.currentQuestion.responses.every(
        (item) => item?.text?.trim() !== ""
      );
      return !hasAllResponses || this.text?.trim() === "";
    },
    competencyName() {
      return this.currentQuestion.competencyName;
    },
    subCompetencyName() {
      return this.currentQuestion.subCompetencyName;
    },
  },
  methods: {
    ...mapActions({
      setQuestionText: "products/setQuestionText",
      setCompetencyQuestion: "products/setCompetencyQuestion",
      getSurveyCompetencies: "products/getSurveyCompetencies",
      resetSurveyCompetenies: "products/resetSurveyCompetenies",
    }),
    async save() {
      const question = this.currentQuestion;
      const subCompId = question.subCompetencyId;
      const payload = {
        id: subCompId,
        question: {
          text: question.text,
          responses: question.responses,
        },
      };
      if (question.id) payload.question.id = question.id;
      await this.setCompetencyQuestion({ id: subCompId, payload }).then(() => {
        this.resetSurveyCompetenies();
        this.getSurveyCompetencies(this.productDetails.id);
      });
    },
  },
};
</script>
