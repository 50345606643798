<template>
  <div class="flex gap-2 mb-3 w-full">
    <TextArea
      :label="`Answer option ${index + 1}`"
      class="w-full"
      :defaultValue="text"
      :onChange="(value) => (text = value)"
      :customHeight="'min-h-36 h-36'"
      max-length="500"
    />
    <InputField
      label="Score"
      class="ml-auto w-50"
      :defaultValue="score"
      readonly
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

import InputField from "../../../../components/InputField/InputField.vue";
import TextArea from "../../../../components/TextArea";

export default {
  name: "AnswerScore",
  components: { InputField, TextArea },
  props: {
    index: { default: null, type: Number },
    id: { default: null, type: Number },
  },
  computed: {
    ...mapState({
      questions: (state) => state.products.questions,
    }),
    response() {
      return this.currentQuestion?.responses[this.index];
    },
    currentQuestion() {
      return this.questions.find((item) => item.subCompetencyId === this.id);
    },
    text: {
      get() {
        return this.response.text;
      },
      set(value) {
        this.setResponseText({
          value,
          index: this.index,
          id: this.id,
        });
      },
    },
    score() {
      return this.response.value.toString();
    },
  },
  methods: {
    ...mapActions({
      setResponseText: "products/setResponseText",
    }),
  },
};
</script>
